<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h3 class="mt-5 pt-4">Articulos anteriores</h3>
    </v-row>
    <v-row class="d-flex justify-center mt-1">
      <v-col cols="12" lg="4" class="mt-2" v-for="(card, i) in cards" :key="i">
        <v-card>
          <v-img :src="card.src" height="325px"></v-img>
          <h4 class="ma-4" v-text="card.titulo"></h4>
          <v-card-text class="pt-0" v-text="card.texto"></v-card-text>
          <v-card-actions>
            <v-btn text color="indigo accent-4" :to="card.to"> LEER</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'OtrosArticulos',

  data() {
    return {
      cards: [
      {
          titulo: 'Siete pasos para dejar de ser ingrato',
          src: `${process.env.BASE_URL}siete_pasos_para_dejar_de_ser_ingrato.png`,
          flex: '4',
          texto: 'Días antes de Acción de Gracias, uno comienza a prepararse y a meditar...',
          to: `articulos/siete_pasos_para_dejar_de_ser_ingrato`,
        },
      {
          titulo: 'San Martín de Tours',
          src: `${process.env.BASE_URL}san_martin_de_tours.jpg`,
          flex: '4',
          texto: 'El 11 de noviembre se conmemora el Día de la Orden de los Caballeros”, fecha en que se celebra...',
          to: `articulos/san_martin_de_tours`,
        },
      {
          titulo: 'A plena Luz',
          src: `${process.env.BASE_URL}a_plena_luz.jpg`,
          flex: '4',
          texto: 'La tendencia natural de la Vida es el bien, el sentido común, el afecto, la pureza, la verdad y la...',
          to: `articulos/a_plena_luz`,
        },
      ],
    };
  },
};
</script>
