<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Carta al Espíritu de Navidad
              </v-card-title>
              <v-card-subtitle
                >Puedes realizar la carta al Espíritu de la Navidad solicitando los deseos de tu corazón.</v-card-subtitle
              >

              <v-card-actions>
                <v-btn text color="indigo accent-4">
                  <a href="carta_a_nativitas.pdf" target="_blank" rel="noopener noreferrer">
                    Descargar Carta al Espíritu de la Navidad
                  </a>
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/carta_a_nativitas.png"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                El árbol de Navidad eres tú
              </v-card-title>
              <v-card-subtitle
                >Simbólicamente, el árbol de Navidad somos nosotros con nuestro “Concepto Inmaculado de Perfección” desenvuelto, representado por las...</v-card-subtitle
              >
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo1">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/el_arbol_de_navidad_eres_tu.jpg"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Santa Claus</v-card-title
              >

              <v-card-subtitle
                >Cada 6 de diciembre se celebra el día de Santa Claus, ser de Sexto Rayo Oro Rubí de la Provisión Divina, que es conocido...
                </v-card-subtitle
              >
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo3">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/santa_claus.png"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Ser agradecido</v-card-title
              >

              <v-card-subtitle
                >La Gratitud es un aspecto de Dios, del Tercer Rayo Rosa; sentimiento de aprecio y agradecimiento a Dios, a la vida y a los seres humanos por las bendiciones que hemos recibido...
              </v-card-subtitle>
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo2">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/ser_agradecido.jpg"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="indigo lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="ultimos-articulos__llave-tonal">
              <v-card-title class="headline">
                Llave tonal de la semana</v-card-title
              >

              <v-card-subtitle class="pb-1"
                >Llave Tonal de la Madre María</v-card-subtitle
              >
              <v-card-text class="pb-0"
                >"Ave Verum Corpus", K 618, de Wolfgang Amadeus Mozart.</v-card-text
              >
              <v-card-actions class="ultimos-articulos__audio">
                <audio
                  src="../../assets/audio/07 AVE VERUM CORPUS K 618 - WOLFGANG AMADEUS MOZART - MADRE MARIA.mp3"
                  autoplay
                  controls
                ></audio>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "UltimosArticulos",
  data() {
    return {
      url: process.env.BASE_URL,
    };
  },
  methods: {
    irArticulo1() {
      this.$router.push("articulos/el_arbol_de_navidad_eres_tu");
    },
    irArticulo2() {
      this.$router.push("articulos/ser_agradecido");
    },
    irArticulo3() {
      this.$router.push("articulos/santa_claus");
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 700px) {
  .ultimos-articulos__imagen {
    display: none;
  }

  .ultimos-articulos__llave-tonal {
    max-width: 100%;
  }

  .ultimos-articulos__audio {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
  }
}
</style>
